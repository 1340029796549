import { APIFilter } from '@/utils/api'

export default {
  async selectLordenTrabajo (Vue, filter, search, sorter, page, idordenTrabajo, idsubsis) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idorden_trabajo', idordenTrabajo)
      .addExact('idsubsis', idsubsis)
      .addGT('estado', 0)
    const resp = await Vue.$api.call(
      'lordenTrabajo.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLordenTrabajoRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idlorden_trabajo', pks)
    const resp = await Vue.$api.call('lordenTrabajo.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
